/* Font Used */
@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500&family=Lora&family=Quattrocento&display=swap");

/* 
font-family: 'Cinzel', serif; //heading
font-family: 'Lora', serif; //small text, link
font-family: 'Quattrocento', serif; //subheading
*/

:root {
  /* Color */
  --whiteColor: #eeeeee;
  --blackColor: #222831;
  --greyColor: #393e46;
  /* --greyColor: #393e465d; */
  /* Font Size */
  --smallText: 0.5rem;
  --mediumText: 1rem;
  --largeText: 2rem;
}

::-webkit-scrollbar {
  width: 0.25em;
  height: 0.5em;
}

::-webkit-scrollbar-thumb {
  background: var(--greyColor);
  border-radius: 1em;
}

.wrapper {
  min-height: 100svh;
  display: flex;
  flex-direction: column;
}

.footer {
  height: fit-content;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  -webkit-box-align: center;
}

.footer-brand {
  display: inline-block;
  padding: 8px 25px;
  margin-top: 10px;
}

.footer-social-menu {
  display: flex;
  margin-bottom: 8px;
  padding: 20px 0;
  -webkit-box-pack: center;
  justify-content: center;
  align-items: center;
  -webkit-box-align: center;
  font-family: "Lora", serif;
}

.content {
  flex-grow: 1;
}

* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  margin: 0%;
  padding: 0%;
}

body {
  background-color: var(--whiteColor);
}

p {
  font-family: "Lora", serif;
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 999;
  padding: 0.5rem 1%;
}

.navbar {
  padding: 0 15px;
}

.nav-link {
  color: white;
  font-weight: 600;
  margin-left: 1.5em;
  text-align: center;
  font-family: "Lora", serif;
}

.nav-link:hover {
  color: white;
  font-weight: 700;
}

.nav-link-underline {
  width: 0%;
  border-bottom: 2px solid white;
  opacity: 1;
  height: 5px;
}

.active-menu {
  width: 100%;
  border-bottom: 2px solid white;
  opacity: 1;
  height: 5px;
}

.email-text {
  font-size: 16px;
  font-weight: 600;
}

.e-text {
  color: black;
  font-size: 16px;
  /* margin: 0 10px; */
  font-family: "Lora", serif;
}

.nav-link:hover .nav-link-underline {
  animation: displayUnderline 250ms linear forwards;
}

@keyframes displayUnderline {
  0% {
    width: 0%;
  }
  25% {
    width: 25%;
  }
  50% {
    width: 50%;
  }
  75% {
    width: 75%;
  }
  100% {
    width: 100%;
  }
}

.nav-item {
  /* background-color: blue; */
  margin: 0 auto;
  width: 100%;
}

.logo-navbar {
  width: 150px;
}

#check {
  display: none;
}

.icons {
  display: none;
  cursor: pointer;
  position: absolute;
  right: 5%;
  bottom: 0%;
  /* top: 5%; */
}

a:hover {
  color: var(--greyColor);
}

.home-footer {
  position: absolute;
  bottom: 2.5%;
  padding: 0 15px;
  width: 100%;
  z-index: 999;
}
.circle-border {
  position: relative;
  width: fit-content;
}

.circle-border-img {
  background-color: white;
  padding: 10px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.gallery-grid {
  width: 20px;
  height: 20px;
  margin: 15%;
}

.gallery-text {
  background-color: rgba(0, 0, 0, 0.384);
  position: absolute;
  bottom: 0%;
  padding: 13px 30px 13px 60px;
  /* width: fit-content; */
  border-radius: 5em;
  /* margin: -1px -10px; */
  /* z-index: -1; */
  display: none;
  /* text-align: center; */
  z-index: -1;
  color: var(--whiteColor);
  font-weight: 700;
  text-transform: uppercase;
  width: 250px;
  text-align: center;
  font-family: "Quattrocento", serif;
  letter-spacing: 0.1em;
}

.circle-border-img:hover .gallery-grid {
  /* transform: rotate(90deg); */
  animation: rotateGrid 100ms linear forwards;
}
.circle-border-img:hover .gallery-text {
  /* transform: rotate(90deg); */
  display: block;
  animation: textDisplay ease-in-out 100ms forwards;
}

@keyframes textDisplay {
  0% {
    opacity: 0;
    transform: translateX(0%);
  }
  99% {
    opacity: 1;
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes rotateGrid {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(90deg);
  }
}

.home-slider {
  height: 100svh;
  overflow: hidden !important;
  position: relative;
}

.gallery .overlay {
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background: var(--whiteColor);
  opacity: 0.9;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff3e1c', endColorstr='#ff8c00', GradientType=1 );
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}

.home-slider .banner .video {
  z-index: -2;
  position: fixed;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.gallery .banner img {
  z-index: -2;
  position: fixed;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* Home Slider */
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-pagination-bullet {
  width: 200px;
  height: 200px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color: #000;
  background: rgba(0, 0, 0, 0.2);
}

.swiper-pagination-bullet-active {
  color: #fff;
  background: #007aff;
}

/* Social Icons */

.social-icons {
  display: inline-flex;
  position: absolute;
  bottom: 2%;
  right: 15px;
}

.social-icons a {
  position: relative;
  width: 50px;
  height: 50px;
  margin: 0 6px;
  -webkit-box-pack: center;
  align-items: center;
  justify-content: center;
  -webkit-box-align: center;
  display: flex;
}

.pie-wrapper {
  height: 50px;
  width: 50px;
  float: left;
  position: relative;
}
.pie-wrapper:nth-child(3n + 1) {
  clear: both;
}
.pie-wrapper .pie {
  height: 50px;
  width: 50px;
  clip: rect(0, 50px, 50px, 0);
  left: 0;
  position: absolute;
  top: 0;
  /* margin-left: -16px; */
}
.pie-wrapper .pie .half-circle {
  height: 50px;
  width: 50px;
  border: 0.15em solid #3498db;
  border-radius: 50%;
  clip: rect(0, 25px, 50px, 0);
  left: 0;
  position: absolute;
  top: 0;
}
.pie-wrapper .label {
  /* background: #34495e; */
  border-radius: 50%;
  bottom: 0;
  /* color: #ecf0f1; */
  cursor: default;
  display: block;
  /* font-size: 0.25esm; */
  left: 0;
  /* line-height: 2.8esm; */
  position: absolute;
  right: 0;
  /* text-align: center; */
  top: 0;
  z-index: -1;
}
.pie-wrapper .label .smaller {
  color: #bdc3c7;
  /* font-size: 0.45em; */
  /* padding-bottom: 20px; */
  vertical-align: super;
}
.pie-wrapper .shadow {
  height: 50px;
  width: 50px;
  border: 0.15em solid black;
  border-radius: 50%;
}
.pie-wrapper.style-2 .label {
  color: #7f8c8d;
}
.pie-wrapper.style-2 .label .smaller {
  color: #bdc3c7;
}
.pie-wrapper.progress-45 .pie .half-circle {
  border-color: var(--whiteColor);
}
.pie-wrapper.progress-45 .pie .left-side {
  transform: rotate(180deg);
}
.pie-wrapper.progress-45:hover .pie .left-side {
  animation: rotateProgress 250ms linear forwards;
}
.pie-wrapper.progress-45 .pie .right-side {
  display: none;
}

@keyframes rotateProgress {
  0% {
    transform: rotate(180deg);
  }
  50% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.social-icon-img {
  /* width: 30px;
  height: 30px; */
  font-size: 20px;
  color: var(--whiteColor);
}

.youpic-img {
  width: 30px;
  height: 30px;
  margin: 20%;
}

.px500_icon {
  width: 30px;
  height: 30px;
  margin: 20%;
}

.fa-facebook-f {
  margin: 30% 37.5%;
}

.fa-twitter {
  margin: 30%;
}

.fa-instagram {
  margin: 30% 32.5%;
}

.main-text {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  color: var(--whiteColor);
  z-index: 2;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.main-small-header {
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 24px;
  letter-spacing: 6px;
  text-transform: uppercase;
}

.main-header {
  letter-spacing: 18px;
  font-size: 70px;
  line-height: 80px;
  margin: 16px 0;
  text-transform: uppercase;
  max-width: 970px;
  z-index: 1;
  position: relative;
  font-weight: 700;
}

.main-categories {
  display: flex;
  margin-top: 24px;
  -webkit-box-pack: center;
  justify-content: center;
}

.main-category-container {
  display: flex;
}

.main-category {
  position: relative;
  overflow: hidden;
  font-weight: 500;
  color: white;
  text-decoration: none;
  font-size: 18px;
  letter-spacing: 3px;
}

.w-inline-bock {
  display: inline-block;
  max-width: 100%;
}

.main-text-underline {
  width: 0%;
  border-bottom: 2px solid white;
  /* margin-top: 10px; */
  /* background-color: white; */
  opacity: 1;
  height: 2px;
  /* display: none; */
}

.main-category:hover .main-text-underline,
.footer-social-link:hover .footer-social-underline {
  animation: displayUnderline 500ms linear forwards;
}

@keyframes displayUnderline {
  0% {
    width: 0%;
  }
  25% {
    width: 25%;
  }
  50% {
    width: 50%;
  }
  75% {
    width: 75%;
  }
  100% {
    width: 100%;
  }
}

.dot-separator {
  display: inline-block;
}

.main-dot-separator {
  padding: 0 16px;
}

.w-condition-invisible {
  display: none !important;
}

/* Gallery */
.gallery-page-header {
  background-color: red;
  margin-bottom: 15%;
}

.gallery-header {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 250px;
}

.gallery-header-img {
  width: 100%;
  height: 250px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0px, 0px, 50%, 50%;
}

.gallery-header-text {
  font-size: 70px;
  line-height: 80px;
  text-transform: uppercase;
  letter-spacing: 18px;
  position: relative;
  z-index: 1;
  text-align: center;
  width: 100%;
  height: 100%;
  padding: 90px 0 0 0;
  color: white;
  font-family: "Cinzel", serif;
  background-color: rgba(0, 0, 0, 0.5);
}

.gallery-section-text {
  margin: 11% 0 0 0;
  text-align: center;
  letter-spacing: 0.5em;
  font-size: 24px;
  line-height: 36px;
  text-transform: uppercase;
  font-family: "Quattrocento", serif;
}

.gallery-section-text p {
  font-family: "Quattrocento", serif;
}

.gallery-section-text hr {
  width: 30%;
  margin: auto;
  padding: 10px 0;
}

.gallery-section {
  /* background-color: red; */
  /* margin-top: 10px; */
  display: flex;
  /* margin-bottom: 20px; */
  flex-wrap: wrap;
  padding: 1em;
  width: 80%;
  margin: auto;
}

.gallery-list-item {
  display: flex;
  width: 400px;
  padding: 15px 10px;
  -webkit-box-flex: 0;
  flex: 0 auto;
  margin: 1.5em auto;
  /* background-color: rebeccapurple; */
  border-radius: 0.5em;
}

.gallery-card {
  width: 400px;
  /* box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.5); */
  /* opacity: 0.2; */
  margin: auto;
}

.gallery-card-img {
  height: 300px;
  width: 370px;
  background-position: 50%, 50%;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  margin: auto;
  box-shadow: 0px 0px 24px 6px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.call-to-action {
  margin: 10px 0;
  display: flex;
  overflow: hidden;
}

.action-block {
  position: relative;
  display: flex;
  width: 50%;
  padding-top: 104px;
  padding-bottom: 88px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  height: 400px;
  color: #fff;
}

.action-block:hover {
  color: var(--whiteColor);
}

.action-text {
  position: relative;
  text-align: center;
  z-index: 1;
}
.action-text-style {
  transform: translate3d(0px, 24px, 0px),
    scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
}

.action-small-title {
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 4px;
  text-transform: uppercase;
  font-weight: 700;
}

h5 {
  margin: 16px 0;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
}

.copyright {
  line-height: 24px;
  display: inline-flex;
  /* background-color: red; */
  margin-bottom: 10px;
  /* text-transform: uppercase; */
}

.copyright-text {
  color: black;
  font-size: 14px;
  /* margin: 0 10px; */
  font-family: "Lora", serif;
}

.footer-link {
  position: relative;
  overflow: hidden;
  /* margin-left: 10px; */
  /* text-transform: none; */
  text-decoration: none;
  font-weight: 600;
}

.footer-line-separator {
  padding: 0 5px;
  margin: 0 5px;
}

.footer-social-link {
  position: relative;
  padding: 8px 0;
  font-size: 15px;
  line-height: 24px;
  text-decoration: none;
  color: black;
}

.footer-social-underline {
  /* margin-top: -9px; */
  width: 0%;
  border-bottom: 2px solid black;
  /* margin-top: 10px; */
  /* background-color: white; */
  opacity: 1;
  height: 2px;
}

.contact-details {
  text-align: center;
  margin: 10px 0;
  /* font-size: 24px; */
}

.contact-details a {
  color: black;
}

.form .text-input,
.form .textarea,
.form .label,
.form .button {
  padding: 1em 1.5em;
  appearance: none;
  outline: none;
  line-height: normal;
  border: 1px solid transparent;
  border-radius: 0;
}

.form .text-input,
.form .textarea {
  font: inherit;
  line-height: normal;
  width: 100%;
  box-sizing: border-box;
  display: block;
  padding-left: 0;
  border-bottom-color: rgba(0, 0, 0, 0.3);
  background: transparent;
  outline: none;
  color: black;
}

.form .text-input:placeholder,
.form .textarea:placeholder {
  color: rgba(0, 0, 0, 0.7);
}

.form .text-input:-webkit-autofill,
.form .textarea:-webkit-autofill {
  box-shadow: 0 0 0px 1000px white inset;
  border-top-color: white;
  border-left-color: white;
  border-right-color: white;
}

.form .error.text-input,
.form .error.textarea,
.error .form .text-input,
.form .error .text-input,
.error .form .textarea,
.form .error .textarea {
  border-color: transparent transparent red transparent;
}

.form:not(.has-floated-label) .text-input:active,
.form:not(.has-floated-label) .textarea:active,
.form:not(.has-floated-label) .text-input:focus,
.form:not(.has-floated-label) .textarea:focus {
  border-color: transparent transparent black transparent;
}

.form .label {
  position: absolute;
  z-index: 10;
  pointer-events: none;
  padding-left: 0;
}

.form .label {
  top: 0;
  left: 0;
  color: rgba(0, 0, 0, 0.7);
  transition: color 0.3s;
}

.active .form .label,
.form .active .label {
  font-size: 0.75em;
  line-height: 1;
  font-weight: 600;
  text-transform: uppercase;
  padding: 0;
  color: rgba(0, 0, 0, 0.7);
  background: white;
}

.focus .form .label,
.form .focus .label {
  color: black;
}

.form.has-floated-label .field:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  border-bottom: 1px solid black;
  transition: width 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.form.has-floated-label .field.focus:after {
  width: 100%;
}

.form .button {
  font: inherit;
  line-height: normal;
  cursor: pointer;
  background-color: black;
  color: white;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.14286em;
}

.form .button:hover,
.form .button:focus,
.form .button:active {
  color: white;
  background: black;
}

.form .button:active {
  position: relative;
  top: 1px;
  left: 1px;
}

.form {
  max-width: 50em;
  margin: 0 auto;
  padding: 1em 2em;
  box-sizing: border-box;
  overflow: hidden;
}

.form .field {
  position: relative;
  width: 100%;
  margin-bottom: 1.5em;
  float: left;
}

.form .label {
  position: absolute;
  display: flex;
  transition: all ease 300ms;
}

input:focus + label,
textarea:focus + label,
input:valid + label,
textarea:valid + label {
  transform: translateY(-27px);
}

.email-invalid input:invalid + label {
  transform: translateY(-27px);
}

@media screen and (min-width: 40em) {
  .form .field.half {
    width: calc(50% - 2em);
    margin-right: 2em;
  }
  .form .field.half + .half {
    margin-left: 2em;
    margin-right: 0;
  }
}
.form .field:last-child {
  float: right;
  width: auto;
}
.form .textarea {
  max-width: 100%;
}

.action-arrow-icon {
  z-index: 1;
}

.action-arrow {
  transform: translate3d(0px, -24px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  opacity: 0;
  display: none;
  width: 60px;
  height: 60px;
  margin: 20px 0 0 0;
}

.action-block:hover .action-arrow {
  /* opacity: 1; */
  display: block;
  animation: actionIcon 500ms linear forwards;
}

@keyframes actionIcon {
  0% {
    opacity: 0.25;
    transform: translateY(-50px);
  }
  50% {
    opacity: 0.5;
    transform: translateY(-25px);
  }
  100% {
    opacity: 1;
    /* transform: translateY(0px); */
  }
}

.about-me {
  margin: 20px 0;
  display: flex;
  overflow: hidden;
  width: 100%;
}

.my-photo {
  position: relative;
  display: flex;
  width: 40%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  align-items: flex-end;
}

.my-photo img {
  width: 550px;
  height: 550px;
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.5);
  border-radius: 0.5em;
}

.about-me-text {
  position: relative;
  display: flex;
  width: 60%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  /* flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  align-items: center; */
}

.about-me-text div {
  width: 60%;
  margin: auto;
}

.about-me-text div p {
  font-size: 18px;
}

.mobile-display {
  display: inline-flex;
}

.fullscreenImageView {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.9);
  /* background-color: red; */
  z-index: 9999;
  overflow: hidden;
}

.fullscreenImageView img {
  max-width: 50%;
  max-height: 90vh;
  border-radius: 1em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.closeButton {
  color: white;
  top: 0;
  right: 2%;
  font-size: 50px;
  position: absolute;
  cursor: pointer;
}

.fullscreenImageView h1 {
  color: white;
}

.fullscreen-image {
  display: block;
}

.fullscreen-image {
  overflow: hidden;
}

.no-image {
  display: none;
}

.background-slider {
  width: 100%;
  height: 100vh;
  opacity: 0.1;
  overflow: hidden;
  position: fixed;
}

.fa-bars {
  color: var(--whiteColor);
  font-size: 24px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .mobile-display {
    display: none;
  }

  .main-dot-separator {
    padding: 0 7px;
  }
  .gallery-page-header {
    margin-bottom: 55%;
  }

  .icons {
    display: inline-flex;
  }

  /* #check:checked ~ .icons #menu-icon {
    display: none;
  } */

  #check:checked ~ .navbar {
    height: 15rem;
  }

  #check:checked ~ .navbar .nav-link {
    transform: translateY(0);
  }

  .navbar {
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(50px);
    width: 100%;
    top: 100%;
    left: 0;
    position: absolute;
    display: block;
    text-align: center;
    transition: 0.3s ease;
    height: 0;
    overflow: hidden;
  }

  .nav-link {
    margin: 1.5rem 0;
    width: 40%;
    margin: 1.5rem auto;
    transition: 0.3s ease;
    transform: translateY(-50px);
  }

  .gallery-header {
    height: 200px;
  }

  .gallery-header-img {
    height: 200px;
  }
  .gallery-header-text {
    font-size: 20px;
    /* margin: 0 2.5%; */
    width: 100%;
  }
  .gallery-section {
    /* margin-top: 10px; */
    display: flex;
    /* margin-bottom: 20px; */
    flex-wrap: wrap;
    padding: 1em;
    width: 100%;
    margin: auto;
  }
  .gallery-list-item {
    width: 100%;
    height: 100%;
    margin: 1em auto;
  }

  .gallery-card-img {
    width: 100%;
  }

  .fullscreenImageView img {
    max-width: 90%;
  }

  .closeButton {
    top: 1%;
    right: 5%;
  }
  .copyright {
    margin-bottom: 0px;
    /* text-transform: uppercase; */
    /* flex-direction: column; */
  }

  .copyright-text {
    font-size: 10px;
  }
  .about-me {
    flex-direction: column;
  }

  .my-photo {
    width: 90%;
  }

  .my-photo img {
    width: 300px;
    height: 300px;
  }

  .about-me-text {
    width: 100%;
  }

  .about-me-text div {
    width: 100%;
    padding: 10px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
